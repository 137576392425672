.airplanes {
    h1 {
        margin:0;
        padding:30px 15px;
        letter-spacing: 2px;
        color:$black;
        background:$gray-lighter;
    }

    .flex-row {
        margin: 30px 0;
        border: 1px solid $gray-lighter;

        .img-container {
            background-position: center center !important;
            background-size: cover !important;
            min-height:150px;
            height:100%;
        }

        .text-container {
            padding: 15px;
            display:flex;
            flex-direction:column;
            justify-content:space-between;

            a {
                &:hover{
                    text-decoration-color:$primary;
                }
            }
        }
    }


    @media(min-width:$screen-md) {
        .text-container {
            padding: 30px;
        }

        .flex-row {
            display: flex;

            .img-link {
                flex: 2;
            }

            .text-container {
                flex: 3;
            }
        }
    }

    @media(min-width:$screen-lg) {
        .flex-row {
            .img-link {
                flex: 1;
            }

            .text-container {
                flex: 2;
            }
        }
    }
}
