footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px 0;

    p {
        margin: 0;
    }
}
