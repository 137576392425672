.features {

    a {
        &:hover {
            text-decoration:none;
            .feature {
                span {
                    background:darken($primary, 10%);
                }
            }
        }
    }

    .feature {
        img {
            width: 100%;
        }

        span {
            display: block;
            background: $primary;
            color: $white;
            padding: 5px;
            font-weight:600;
        }

    }

    @media(min-width:$screen-md) {
        .col-xs-6 {
            margin-bottom:30px;
        }
        .feature {
            span {
                padding: 15px 5px;
            }
        }
    }

    @media(max-width:$screen-md - 1) {
        .col-xs-6 {
            margin-bottom:15px;
            &:nth-child(2n + 1) {
                padding-right: 7.5px;
            }

            &:nth-child(2n + 2) {
                padding-left: 7.5px;
            }
        }
    }
}
