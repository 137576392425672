@import "slider";
@import "features";
@import "call-to-action";
@import "services";
@import "reviews";

.home {
    @media(min-width:$screen-md) {
        h2 {
            font-size:36px;
        }
    }
}