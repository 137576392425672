.sub-pages {
    margin: $sub-pages-container-margin;
    img {
        margin:0 auto;
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    .page-title {
        padding: 10px;
    }

    & > div {
        &:nth-child(2n + 1) {
            @media(min-width:$screen-sm) and (max-width:$screen-md - 1) {
                clear: left;
            }
        }

        &:nth-child(4n + 1) {
            @media(min-width:$screen-md) {
                clear: left;
            }
        }
    }
}
