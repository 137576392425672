.services {
    border-top: 2px solid blue;
    ul {
        li {
            padding: 15px;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                display: block;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: -webkit-calc(100% - 30px);
                width: calc(100% - 30px);
                height: 1px;
                background: $gray-lighter;
            }

            a {
                font-size: 18px;
                color: $primary;

                .fa {
                    color: $secondary;
                    font-weight: bold;
                    vertical-align: middle;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .flex-container {
        display:flex;
        flex-wrap:wrap;

        & > li {
            width:100%;

            @media(min-width:500px) {
                width:50%;
            }

            @media(min-width:$screen-md) {
                width:33.33%;
            }
        }
    }
}
