.product-slider {
    border: 1px solid $gray-lighter;
    padding:5px;

    .img-slider-for {
        margin-bottom:5px;
    }

    .img-slider {
        img {
            margin:0 2.5px;
        }
    }

    .slick-track {
        margin:0;
    }
}