.product-search {
    padding: 30px 0;
    background: $gray-lighter;

    @media(max-width:$screen-sm - 1) {
        ul {
            & > li {
                &:not(:last-child) {
                    margin-bottom:10px;
                }
            }
        }
    }

    @media(min-width:$screen-sm) {
        ul {
            display:flex;
            align-items:center;

            & > li {
                &:not(:last-child) {
                    margin-right:10px;
                    flex:1;

                }

                input, select {
                    height:43px;
                }
            }
        }
    }
}