.btn {
    border-radius: 0;
    border: none;
    white-space: normal;
    padding:12px 18px;
    font-weight:600;

    &-primary {
        color:$white;
        background:$primary;
        &:hover, &:active, &:focus, &:active:focus, &:active:hover, &[disabled], &[disabled]:hover {
            background:darken($primary, 10%);
        }
    }

    &-default {
        &:hover, &:active, &:focus, &:active:focus, &:active:hover, &[disabled], &[disabled]:hover {
        }
    }

    &-info {
        &:hover, &:active, &:focus, &:active:focus, &:active:hover, &[disabled], &[disabled]:hover {
        }
    }

    &-group {
        @media(max-width: $screen-xs - 1) {
            display:flex;
            flex-direction:column;
        }
    }
}
