/*.flex-container {
    display:flex;
}*/

.background-center {
    color:$white;
    h1, h2, h3, h4, h5, h6 {
        color:$white;
        position:relative;
    }
}

@media(min-width:$screen-md) {
    .column-wrap-2 {
        column-count: 2;
        column-gap: 30px;
    }
}