.gallery {
    margin: $gallery-container-margin;
    &-item {
        width: 100%;

        @media(min-width:$screen-xs) {
            width:calc(50% - #{$gallery-gutter / 2});
        }

        @media(min-width: $screen-md) {
            width:calc(33.33% - #{$gallery-gutter * 2 / 3});
        }

        @media(min-width: $screen-lg) {
            width:calc(25% - #{$gallery-gutter * 3 / 4});
        }
    }


    &-title {
        position:absolute;
        bottom:-$gallery-title-font-size;
        left:0;
        right:0;
        margin:0;
        opacity:0;
        padding: $gallery-title-padding;
        font-size: $gallery-title-font-size;
        line-height: $gallery-title-font-size;
        transition: all .5s;
    }


    a {
        display: block;
        position:relative;
        color: $gallery-font-color;
        overflow:hidden;

        &:before {
            content:'';
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            transition: .5s background-color;
        }

        &:hover {
            cursor:pointer;
            &:before {             
                background-color:$gallery-item-overlay;
            }  
            .gallery-title {
                opacity:1;
                bottom:0;
            }
        }
    }



    &-modal { 
        color:#fff;

        .modal {
            &-dialog {
                max-width:1400px;
                width:100%;
                margin-left:auto;
                margin-right:auto;
                padding:15px;
            }

            &-content {
                background: transparent;
                border: none;
                box-shadow: none;
            }

            &-body {
                padding:0;              
            }

            &-title {
                font-size: 24px;
                line-height:24px;
                padding-top:10px;
                padding-bottom:5px;
            }

            &-description {
                font-size: 15px;
            }
        }

        .modal-close {
            position:static;
        }

        .img-responsive {
            margin:0 auto;
            max-height:350px;

            @media(min-width: $screen-xs) {
                max-height: 700px;
            }

        }

        .img-container {
            display:inline-block;
        }
    }
}