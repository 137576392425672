header {
    & > .container {
        padding-top:15px;
        padding-bottom:15px;
        &:before, &:after {
            content:none;
        }
    }
    .flex-container {
        display:flex;
        justify-content:space-between;
        align-items:center;
    }

    ul {
        li {
            color:$primary;
            font-weight:600;

            a {
                color:$primary;
                &:hover {
                    color: darken($primary, 10%);
                    text-decoration:none;
                }
            }
        }
    }

    .email {

    }

    .phone {
        font-size:34px;
    }
}