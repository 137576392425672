.slider {
    padding: 0;

    .slide-container {
        background-size: cover;
        background-position: center;

        .text-container {
            margin: 15px;
            background: rgba($secondary, .5);
            color:$white;
            padding:15px;
        }
    }

    @media(min-width:$screen-md) {
        .slide-container {
            .text-container {
                margin:60px 0;
                max-width:600px;
            }
        }
    }
}
