html, body {
    font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
}


.grid-section {

    & > * {
        margin: 30px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    img {
        max-width: 100%;
    }
}

.services {
    border-bottom: 2px solid blue;
    margin-bottom: 40px;
}