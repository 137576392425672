.navbar {
    border-bottom: none;

    ul {
        & > li {
            & > a {
                color: $white !important;

                &:hover {
                    background: lighten($secondary, 10%) !important;
                }
            }
        }
    }

    button {
        color: $white;
        border: none;
        border-radius: 0;

        &:hover, &:active, &:focus, &:active:focus, &:active:hover, &[disabled], &[disabled]:hover {
            background-color: rgba($white, .25) !important;
        }
    }

    .navbar-collapse {
        border: none;
    }

    .dropdown-menu {
        & > li {
            border: none !important;

            & > a {
                color: $black !important;

                &:hover {
                    color: $white !important;
                }
            }
        }
    }
}

@media(min-width:$navbar-mobile-breakpoint) {
    .navbar {
        ul {
            & > li {
                border-left: 1px solid lighten($secondary, 10%);

                &:last-child {
                    border-right: 1px solid lighten($secondary, 10%);
                }
            }
        }
    }
}

@media(max-width:$navbar-mobile-breakpoint - 1) {
    .navbar {
        .dropdown-menu {
            & > li {
                border: none !important;

                & > a {
                    color: $white !important;

                    &:hover {
                        color: $white !important;
                    }
                }
            }
        }
    }
}