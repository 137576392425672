.product {
    .description {
        & > ul {
            display:flex;
            flex-wrap: wrap;
            & > li {
                font-size: 13px;
                background:rgba($primary, .15);
                padding: 3px 15px;
                border-radius: 15px;
                margin: 5px 10px 5px 0
            }
        }
    }
}