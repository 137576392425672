.product-tiles {
    display:flex;
    flex-wrap:wrap;
    margin: -15px 0;

    .product-tile {
        width:50%;
        padding:0 15px;
        margin: 15px 0;

        @media(min-width:$screen-xs) {
            width:33.33%;
        }
        @media(min-width:$screen-md) {
            width:25%;
        }

        img {
            width:100%;
        }

        a {
            span {
                background:$primary;
                color:$white;
                display:block;
                text-align:center;
                padding:5px;
            }

            &:hover {
                text-decoration:none;

                span {
                    background: darken($primary, 10%);
                }
            }
        }
    }

    @media(min-width:$screen-md) {
        .product-tile {
            a {
                span {
                    padding: 15px 5px;
                }
            }
        }
    }
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top:30px;
    margin-bottom:0;

    ul {
        display: flex;
        flex-wrap:wrap;
        margin:0;

        li {
            border: 1px solid $gray-lighter;

            &:not(:last-child) {
                border-right: none;
            }

            &.active {
                background: $primary;

                a{
                    color:$white;
                }
            }

            a {
                display: inline-block;
                padding: 5px 15px;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}