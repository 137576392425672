.reviews {
    img {
        border-radius:100%;
        margin:0 auto;
        display:block;
        width:170px;
        height:170px;
    }

    h4 {
        color:$primary;
    }

    @media(max-width:$screen-md - 1) {
        .review {
            img {
                margin-bottom:15px;
            }
        }
    }

    @media(min-width:$screen-md) {
        .review {
            img {
                display:inline-block;
                margin-right:15px;
            }
            .reviews-text {
                vertical-align:middle;
                margin-right: -4px;
                display:inline-block;
                width:calc(100% - 186px);
            }
        }
    }
}