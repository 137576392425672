.grid-banner {
    position:relative;

    .banner-content {
        padding: 60px 0;
    }

    .image-background {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100vw;
        max-width: none;
        min-height:100%;
        background-size:cover;
        background-position:center;
    }

    .text-container {
        padding: 15px;
        background: rgba($black, .5);
        color: $white;
        max-width: 600px;
        position:relative;
    }
}

.grid-section:first-child {
    .container:first-child {
        .col-md-12:first-child {
            .grid-banner:first-child {
                margin-top: -30px;
            }
        }
    }
}