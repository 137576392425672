.airplane, .product {
    .description {
        padding: 15px 15px;
    }

    @media(min-width:$screen-md) {
        .flex-container {
            display: flex;

            .images {
                width: 50%;
            }

            .description {
                width:50%;
            }

            .description {
                padding: 0 30px 30px;
            }
        }
    }
}
